import { MultiPolygon } from 'geojson';

export interface Srs {
  id: number;
  display_name: string;
  group_id: number;
  scope: string;
  auth_name: string;
  auth_srid: string;
  postgis_srid?: number;
  area_of_use: MultiPolygon;
  wkt: string;
  proj: string;
  prj: string;
  is_bookmarked?: boolean;
}

export enum SrsMainAttrs {
  auth_name = "auth_name",
  auth_srid = "auth_srid",
  postgis_srid = "postgis_srid",
}