
import { defineComponent, toRefs, watchEffect, ref, Ref, computed } from 'vue';
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';
import { useHead } from '@vueuse/head';
import { LGeoJson } from '@vue-leaflet/vue-leaflet';
import mapService from '@/services/mapService';
import useSrsDetailed from '../composables/useSrsDetailed';
import AppMap from '../components/AppMap.vue';
import SrsAttributes from '../srs/components/SrsAttributes.vue';
import SrsDefintionTabs from '../srs/components/SrsDefintionTabs.vue';
import { MapOptions } from '../interfaces/MapOptions';
import { SrsMainAttrs } from '../srs/interfaces/Srs';

export default defineComponent({
  name: 'SrsDetailed',
  components: { AppMap, LGeoJson, SrsDefintionTabs, SrsAttributes },
  props: {
    id: {
      type: Number,
      required: true,
    },
  },
  setup(props) {
    const { id } = toRefs(props);
    const { srsData, srsDefinitions, isBookmarked } = useSrsDetailed(id);
    const mapOptions: Ref<MapOptions | null> = ref(null);
    const { commit } = useStore();
    const { t } = useI18n();
    useHead({
      title: computed(() =>
        srsData.value?.display_name
          ? `${srsData.value?.display_name}  | ${t('app.seotitle')}  | NextGIS`
          : ''
      ),
    });

    const attrs = computed(() =>
      Object.keys(SrsMainAttrs).reduce(
        (acc, attr) =>
          srsData.value && srsData.value[attr as keyof typeof SrsMainAttrs]
            ? {
                ...acc,
                [attr]: srsData.value[attr as keyof typeof SrsMainAttrs],
              }
            : acc,
        {}
      )
    );

    watchEffect(() => {
      if (srsData.value?.area_of_use) {
        mapOptions.value = {
          bounds: mapService.getGeojsonBounds(srsData.value.area_of_use),
        };
      }
    });

    function toggleBookmark() {
      if (isBookmarked.value) {
        commit('srs/DELETE_BOOKMARK', srsData.value?.id);
      } else {
        commit('srs/ADD_BOOKMARK', srsData.value);
      }
    }
    return { srsData, srsDefinitions, mapOptions, isBookmarked, toggleBookmark, attrs, t };
  },
});
