import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-cc6f7fce"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "srs-detailed text-body1" }
const _hoisted_2 = { class: "row q-col-gutter-lg" }
const _hoisted_3 = { class: "col-12 col-md-9 flex" }
const _hoisted_4 = { class: "text-grey q-mb-sm" }
const _hoisted_5 = { class: "col-12 col-md-3" }
const _hoisted_6 = { class: "block q-mb-md q-pt-lg" }
const _hoisted_7 = { class: "srs-detailed__map rounded-borders" }
const _hoisted_8 = {
  key: 1,
  class: "flex justify-center q-my-xl"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_SrsDefintionTabs = _resolveComponent("SrsDefintionTabs")!
  const _component_SrsAttributes = _resolveComponent("SrsAttributes")!
  const _component_LGeoJson = _resolveComponent("LGeoJson")!
  const _component_AppMap = _resolveComponent("AppMap")!
  const _component_q_spinner = _resolveComponent("q-spinner")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.srsData)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          _createVNode(_component_q_btn, {
            class: "text-grey q-mr-md",
            flat: "",
            dense: "",
            icon: "mdi-arrow-left",
            label: _ctx.t('srs.backToCatalog'),
            "no-caps": "",
            to: "/"
          }, null, 8, ["label"]),
          _createVNode(_component_q_btn, {
            class: "text-grey",
            flat: "",
            dense: "",
            icon: _ctx.isBookmarked ? 'mdi-bookmark' : 'mdi-bookmark-outline',
            label: _ctx.isBookmarked ? _ctx.t('srs.inBookmarks') : _ctx.t('srs.addBookmark'),
            "no-caps": "",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.toggleBookmark()))
          }, null, 8, ["icon", "label"]),
          _createElementVNode("h1", {
            class: _normalizeClass(['q-mt-sm', { 'text-h4': _ctx.$q.screen.xs, 'text-h3': _ctx.$q.screen.gt.xs }])
          }, _toDisplayString(_ctx.srsData.display_name), 3),
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.t('srs.definitions')), 1),
              _createVNode(_component_SrsDefintionTabs, {
                class: "srs-detailed__definitions rounded-borders",
                definitions: _ctx.srsDefinitions,
                "srs-name": _ctx.srsData.display_name
              }, null, 8, ["definitions", "srs-name"])
            ]),
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("div", _hoisted_6, [
                _createVNode(_component_SrsAttributes, { attrs: _ctx.attrs }, null, 8, ["attrs"])
              ]),
              _createElementVNode("div", _hoisted_7, [
                _createVNode(_component_AppMap, { options: _ctx.mapOptions }, {
                  default: _withCtx(() => [
                    _createVNode(_component_LGeoJson, {
                      geojson: _ctx.srsData.area_of_use
                    }, null, 8, ["geojson"])
                  ]),
                  _: 1
                }, 8, ["options"])
              ])
            ])
          ])
        ], 64))
      : (_openBlock(), _createElementBlock("div", _hoisted_8, [
          _createVNode(_component_q_spinner, {
            color: "primary",
            size: "3em"
          })
        ]))
  ]))
}